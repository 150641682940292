import { Link } from 'gatsby'
import React from 'react'
import Layout from '../components/layout'
import Nav from '../components/nav/nav'
import '../css/security-usecase.scss'
import { FaAngleLeft } from "react-icons/fa";
import JourneyMapImg from '../images/digital-usecase/delware-tech/journey-map.png'

const NetworkSecurityAudit = () => {
    return (
        <div className="security-usecase">
            <Layout>
                <Nav
                    isWhite={true}
                />

                <div className="px-5 pt-4">
                    <Link to="/security-usecase"><p className="cursor black-link-text mb-0 my-auto"><span className="mr-1"><FaAngleLeft /></span>Risk Management</p></Link>
                </div>

                <div className="digital-usecase pb-5 pt-4">
                    <div className="bg-network-security"></div>
                </div>


                <div className="container">
                    <h1 className="font-weight-bold text-center mt-md-5 big-text">Reinforcing network security for a global conglomerate</h1>

                    <p className="text-center mt-4 mb-4 pb-2 text-uppercase inner-page-breadcrumb"><span className="text-secondary mr-2">Network security and audit</span>delware tech</p>

                    <div style={{ borderBottom: '5px solid #E41700' }}></div>
                </div>

                <div className="py-5 my-5"></div>

                <div className="bg-client-usecase">
                    <div className="container">
                        <div className="row pt-5 mt-5 pb-5">
                            <div className="col-md-6 my-5">
                                <p className="font-weight-bold" style={{ fontSize: '1.5rem' }}>The Client</p>

                                <p className="text-secondary" style={{ fontSize: '1.15rem', lineHeight: '30px' }}>Our client — a global conglomerate whose operations include manufacturing, services, logistics and warehousing, agriculture, and real estate — sought to reinforce her network security and cut down on unauthorised broadband usage that was slowing down the network and affecting productivity.</p>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-solution-usecase">
                    <div className="container">
                        <div className="row pt-5 mt-5 pb-5">
                            <div className="col-md-6 mx-auto my-5">
                                <p className="font-weight-bold" style={{ fontSize: '1.5rem' }}>Our Solution</p>

                                <p className="text-secondary" style={{ fontSize: '1.15rem', lineHeight: '30px' }}>
                                    We came to the rescue with policies and practices to prevent unauthorized network access, monitor usage and flag misuse.
                                </p>

                                <p className="text-secondary" style={{ fontSize: '1.15rem', lineHeight: '30px' }}>
                                    We also implemented an Automatic Failover on primary and secondary links, to transfer applications to a backup server during downtime which was able to ensure unhindered operations across the organization with regards to internet usage.
                                </p>

                                <p className="text-secondary" style={{ fontSize: '1.15rem', lineHeight: '30px' }}>
                                    As a global conglomerate, our client has remote offices spread across regions, which meant multiple internet-bound traffic from every corner. To solve this, we implemented an Internet Breakout to offload internet traffic flows from remote sites to a central hub, which allowed for more centrally-controlled security even with decentralized access across all usage points.
                                </p>

                            </div>
                        </div>
                    </div>
                </div>


                <div className="container">
                    <div className="row pt-5 mt-5">
                        <div className="col-md-6 mx-auto">
                            <img src={JourneyMapImg} alt="Journey Map" className="img-fluid" />
                            <p className="text-secondary text-center mt-1">Ideation process to hack security process</p>
                        </div>
                    </div>
                </div>

                <div className="my-5 py-5"></div>

                <div className="bg-impact-usecase">
                    <div className="container">
                        <div className="row pt-5 mt-5">
                            <div className="col-md-6 my-5">
                                <p style={{ fontSize: '1.5rem' }} className="font-weight-bold">The Impact</p>

                                <ul>
                                    <li><p style={{ fontSize: '1.15rem', lineHeight: '30px' }} className="text-secondary mb-1">Improved internet speed across local and remote offices. </p></li>
                                    <li><p style={{ fontSize: '1.15rem', lineHeight: '30px' }} className="text-secondary mb-1">Allowed for unhindered workflow and better productivity. </p></li>
                                    <li><p style={{ fontSize: '1.15rem', lineHeight: '30px' }} className="text-secondary mb-1">Ensured equality of service to maximize the profit of applications.</p></li>
                                    <li><p style={{ fontSize: '1.15rem', lineHeight: '30px' }} className="text-secondary mb-1">Saved funds by cutting down unnecessary internet spending cost.</p></li>
                                    <li><p style={{ fontSize: '1.15rem', lineHeight: '30px' }} className="text-secondary mb-1">fortified security and protection against malware.</p></li>
                                </ul>


                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row py-5 my-5">
                        <div className="col-md-5 mx-auto mb-5 text-center">
                            <Link to={`/contact`} state={{ path: 'risk' }}><button style={{ height: '48px' }} class="btn btn-red h-100 btn-red-black px-4 no-border-radius">Get started</button></Link>
                        </div>
                    </div>
                </div>
            </Layout >
        </div>
    )
}

export default NetworkSecurityAudit
